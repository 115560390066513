@charset "UTF-8";
* {
  box-sizing: border-box; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, sub, sup, tt, var, u, i, center, strong, b, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  line-height: 1em;
  font-weight: 400;
  vertical-align: baseline;
  font-family: 'Roboto', helvetica, arial, sans-serif;
  list-style-type: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.025); }

main, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

em, i {
  font-style: italic;
  font-family: inherit; }

strong, b {
  font-weight: bold;
  font-family: inherit; }

small {
  font-family: inherit; }

a {
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
  font-family: inherit; }

span {
  font-family: inherit; }

input, select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  border: 0 none;
  padding: 3px;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  outline: none; }

button {
  cursor: pointer;
  background: none;
  border: 0 none;
  font-family: inherit;
  font-size: inherit;
  outline: none; }

select::-ms-expand {
  /* IE 10 select dropdown fix */
  display: none; }

/* Main basic layout */
.content {
  width: 100%;
  max-width: 100%;
  padding: 0 3rem; }

.flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.flex-row {
  flex-direction: row; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-column {
  flex-direction: column; }

.flex-column-reverse {
  flex-direction: column-reverse; }

.flex-center {
  align-items: center;
  justify-content: center; }

.flex-stretch {
  align-items: stretch; }

.flex-space-between {
  justify-content: space-between; }

.flex-wrap {
  flex-wrap: wrap; }

.full {
  flex: 1 1 100%; }

.half {
  flex: 1 1 50%; }

.third {
  flex: 1 1 33.33%; }

.two-third {
  flex: 1 1 66.66%; }

.quarter {
  flex: 1 1 25%; }

.three-quarter {
  flex: 1 1 75%; }

.fifth {
  flex: 1 1 20%; }

.auto {
  flex: 1 1 auto; }

.no-grow {
  flex-grow: 0; }

.no-shrink {
  flex-shrink: 0; }

/* Media queries for responsive design layout */
@media only screen and (max-width: 1199px) {
  .content {
    max-width: 100%; } }

@media only screen and (max-width: 880px), only screen and (max-width: 1000px) and (orientation: portrait) {
  .flex-row {
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column; }
  .flex-row-reverse {
    flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse; }
  .full, .half, .third, .two-third, .quarter, .three-quarter, .fifth {
    flex: 1 1 auto; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes slide-in-top {
  0% {
    transform: translate(0, -4rem); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-top {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(0, -4rem); } }

@keyframes slide-in-bottom {
  0% {
    transform: translate(0, 4rem); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-bottom {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(0, 4rem); } }

@keyframes slide-in-left {
  0% {
    transform: translate(-4rem, 0); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-left {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-4rem, 0); } }

@keyframes slide-in-right {
  0% {
    transform: translate(4rem, 0); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-right {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(4rem, 0); } }

@keyframes slide-fade-in-top {
  0% {
    opacity: 0;
    transform: translate(0, -4rem); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-top {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(0, -4rem); } }

@keyframes slide-fade-in-bottom {
  0% {
    opacity: 0;
    transform: translate(0, 4rem); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-bottom {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(0, 4rem); } }

@keyframes slide-fade-in-left {
  0% {
    opacity: 0;
    transform: translate(-4rem, 0); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-left {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(-4rem, 0); } }

@keyframes slide-fade-in-right {
  0% {
    opacity: 0;
    transform: translate(4rem, 0); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-right {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(4rem, 0); } }

@keyframes twist-in {
  0% {
    transform: rotateY(-90deg); }
  100% {
    transform: rotateY(0deg); } }

@keyframes bounce-in {
  0% {
    transform: scale(0); }
  80% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@keyframes bounce-in-vertically-centered {
  0% {
    transform: translate(0, -50%) scale(0); }
  80% {
    transform: translate(0, -50%) scale(1.2); }
  100% {
    transform: translate(0, -50%) scale(1); } }

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(0.25) translate(0, -1.25rem) rotateX(-80deg); }
  100% {
    opacity: 1;
    transform: scale(1) translate(0, 0) rotateX(0); } }

@keyframes scale-fade-in {
  0% {
    opacity: 0;
    transform: scale(0.5); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes scale-fade-out {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.2); } }

@keyframes scale-in-left-to-right {
  0% {
    opacity: 0;
    transform: scaleX(0);
    transform-origin: left center; }
  100% {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left center; } }

@keyframes nav-collapse {
  0% {
    flex: 0 0 17.5rem;
    width: 17.5rem; }
  100% {
    flex: 0 0 5rem;
    width: 5rem; } }

@keyframes nav-expand {
  0% {
    flex: 0 0 5rem;
    width: 5rem; }
  100% {
    flex: 0 0 17.5rem;
    width: 17.5rem; } }

/* =========== Primary Palette === */
/* ========== Secondary Palette === */
/* ====== Grey Palette === */
/* ================================================================= Push Auto === */
/* =========================================================== Pseudo Elements === */
/* ========================================================== Responsive Ratio === */
/* ========================================================= Input Placeholder === */
/* ============================================================= Media Queries === */
/* =================================================================== Z Index === */
/* =============================================== Cheat Hardware Acceleration === */
/* ================================================================== Truncate === */
/* ================================================================ Visibility === */
/* ===================================================== Retina Display Images === */
/* ================================================================== Position === */
/* =================================================================== Convert === */
/* ================================================================== Pre View === */
/* ================================================================== In View === */
/* ============================================================== No Highlight === */
/* =========== Primary Palette === */
/* ========== Secondary Palette === */
/* ====== Grey Palette === */
/* ================================================================= Push Auto === */
/* =========================================================== Pseudo Elements === */
/* ========================================================== Responsive Ratio === */
/* ========================================================= Input Placeholder === */
/* ============================================================= Media Queries === */
/* =================================================================== Z Index === */
/* =============================================== Cheat Hardware Acceleration === */
/* ================================================================== Truncate === */
/* ================================================================ Visibility === */
/* ===================================================== Retina Display Images === */
/* ================================================================== Position === */
/* =================================================================== Convert === */
/* ================================================================== Pre View === */
/* ================================================================== In View === */
/* ============================================================== No Highlight === */
@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes slide-in-top {
  0% {
    transform: translate(0, -4rem); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-top {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(0, -4rem); } }

@keyframes slide-in-bottom {
  0% {
    transform: translate(0, 4rem); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-bottom {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(0, 4rem); } }

@keyframes slide-in-left {
  0% {
    transform: translate(-4rem, 0); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-left {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-4rem, 0); } }

@keyframes slide-in-right {
  0% {
    transform: translate(4rem, 0); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-right {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(4rem, 0); } }

@keyframes slide-fade-in-top {
  0% {
    opacity: 0;
    transform: translate(0, -4rem); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-top {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(0, -4rem); } }

@keyframes slide-fade-in-bottom {
  0% {
    opacity: 0;
    transform: translate(0, 4rem); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-bottom {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(0, 4rem); } }

@keyframes slide-fade-in-left {
  0% {
    opacity: 0;
    transform: translate(-4rem, 0); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-left {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(-4rem, 0); } }

@keyframes slide-fade-in-right {
  0% {
    opacity: 0;
    transform: translate(4rem, 0); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-right {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(4rem, 0); } }

@keyframes twist-in {
  0% {
    transform: rotateY(-90deg); }
  100% {
    transform: rotateY(0deg); } }

@keyframes bounce-in {
  0% {
    transform: scale(0); }
  80% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@keyframes bounce-in-vertically-centered {
  0% {
    transform: translate(0, -50%) scale(0); }
  80% {
    transform: translate(0, -50%) scale(1.2); }
  100% {
    transform: translate(0, -50%) scale(1); } }

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(0.25) translate(0, -1.25rem) rotateX(-80deg); }
  100% {
    opacity: 1;
    transform: scale(1) translate(0, 0) rotateX(0); } }

@keyframes scale-fade-in {
  0% {
    opacity: 0;
    transform: scale(0.5); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes scale-fade-out {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.2); } }

@keyframes scale-in-left-to-right {
  0% {
    opacity: 0;
    transform: scaleX(0);
    transform-origin: left center; }
  100% {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left center; } }

@keyframes nav-collapse {
  0% {
    flex: 0 0 17.5rem;
    width: 17.5rem; }
  100% {
    flex: 0 0 5rem;
    width: 5rem; } }

@keyframes nav-expand {
  0% {
    flex: 0 0 5rem;
    width: 5rem; }
  100% {
    flex: 0 0 17.5rem;
    width: 17.5rem; } }

.gantt-chart {
  position: absolute;
  top: 4rem;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  opacity: 0;
  animation: fade-in 200ms linear forwards;
  animation-delay: 2s;
  overflow: hidden; }

.root[data-loaded="true"] .gantt-chart {
  animation-duration: 200ms;
  animation-delay: 0ms; }

.gantt-chart-rail {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -25%;
  left: -25%;
  transition: none; }
  .gantt-chart-rail[data-moving="forwards"] {
    transform: translate(calc(-100% / 6), 0);
    transition: transform 120ms linear; }
  .gantt-chart-rail[data-moving="backwards"] {
    transform: translate(calc(100% / 6), 0);
    transition: transform 120ms linear; }

.gantt-chart-header {
  width: 100%;
  position: relative;
  z-index: 500; }
  .gantt-chart-header .gantt-chart-header__weeks {
    width: 100%; }

.gantt-chart-header-week {
  flex: 0 0 calc(100% /6);
  width: calc(100% / 6);
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  background: #27323b;
  border-right: solid 1px rgba(255, 255, 255, 0.2); }
  .gantt-chart-header-week:last-child {
    border-right: 0 none; }
  .gantt-chart-header-week .title {
    padding: 1rem 1rem 0.375rem;
    position: relative;
    text-align: center;
    pointer-events: none; }
    .gantt-chart-header-week .title .title__week {
      pointer-events: none;
      user-select: none;
      color: #4ca585;
      font-weight: 500;
      margin-right: 0.625rem; }
    .gantt-chart-header-week .title .title__month {
      pointer-events: none;
      user-select: none;
      font-weight: 300; }
  .gantt-chart-header-week[data-current="true"] .title::before {
    content: "";
    display: block;
    position: absolute;
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    height: 4px;
    background: #ffe500; }
  .gantt-chart-header-week .gantt-chart-header-week__day {
    flex: 0 0 calc(100% / 7);
    width: calc(100% / 7);
    text-align: center;
    padding: 0.625rem;
    position: relative;
    z-index: 10;
    pointer-events: none; }
    .gantt-chart-header-week .gantt-chart-header-week__day span {
      pointer-events: none;
      user-select: none;
      font-size: 0.75em;
      white-space: nowrap; }
    .gantt-chart-header-week .gantt-chart-header-week__day[data-current="true"]::before {
      content: "";
      display: block;
      position: absolute;
      position: absolute;
      top: 0.375rem;
      right: 0.25rem;
      bottom: 0.375rem;
      left: 0.25rem;
      background: #ffe500;
      border-radius: 0.25rem;
      z-index: -10; }
    .gantt-chart-header-week .gantt-chart-header-week__day[data-current="true"] span {
      color: #1d252c;
      font-weight: 500; }
    @media only screen and (max-width: 1400px) {
      .gantt-chart-header-week .gantt-chart-header-week__day {
        padding: 0.5rem 0.25rem; }
        .gantt-chart-header-week .gantt-chart-header-week__day span {
          font-size: 0.675em; } }

.root[data-light-theme="true"] .gantt-chart-header-week {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  border-right: solid 1px rgba(0, 0, 0, 0.2); }

.gantt-chart-grid {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -10;
  cursor: grab; }
  .gantt-chart-grid[data-grabbing="true"] {
    cursor: grabbing; }
  .gantt-chart-grid .gantt-chart-grid__week {
    user-select: none;
    flex: 0 0 calc(100% / 6);
    border-right: solid 1px rgba(255, 255, 255, 0.2);
    position: relative;
    height: 100%; }
    .gantt-chart-grid .gantt-chart-grid__week:last-child {
      border-right: 0 none; }
  .gantt-chart-grid .gantt-chart-grid__day {
    user-select: none;
    flex: 0 0 calc(100% / 7);
    border-right: solid 1px rgba(255, 255, 255, 0.1);
    position: relative;
    height: 100%;
    background: #27323b; }
    .gantt-chart-grid .gantt-chart-grid__day:last-child {
      border-right: 0 none; }
    .gantt-chart-grid .gantt-chart-grid__day[data-disabled="true"] {
      background: #313f4b; }
    .gantt-chart-grid .gantt-chart-grid__day[data-current="true"]::after {
      content: "";
      display: block;
      position: absolute;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      z-index: 10;
      width: 0.25rem;
      transform: translate(-50%, 0);
      background: #ffe500; }

.root[data-light-theme="true"] .gantt-chart-grid .gantt-chart-grid__day {
  border-right: solid 1px rgba(0, 0, 0, 0.1);
  background: #fff; }
  .root[data-light-theme="true"] .gantt-chart-grid .gantt-chart-grid__day[data-disabled="true"] {
    background: #f2f2f3; }

.gantt-chart__projects-container {
  width: 100%;
  position: relative;
  height: 100%; }

.gantt-chart__projects {
  width: 100%;
  position: relative;
  z-index: 50;
  flex: 0 0 auto;
  min-height: 100%;
  cursor: grab;
  transition: transform 50ms linear, opacity 120ms linear;
  opacity: 0; }
  .gantt-chart__projects[data-grabbing="true"] {
    cursor: grabbing; }
  .gantt-chart__projects[data-loaded="true"] {
    opacity: 1; }

body[data-resizing="true"] .gantt-chart__projects {
  cursor: ew-resize; }
  body[data-resizing="true"] .gantt-chart__projects[data-grabbing="true"] {
    cursor: ew-resize; }

.gantt-chart__empty-row {
  width: 100%;
  margin: 0.5rem 0; }

.gantt-chart-project {
  width: 100%;
  align-items: stretch;
  position: relative;
  z-index: 50;
  height: 4.5rem;
  margin: 0.5rem 0;
  border-radius: 0.25rem;
  transition: all 120ms linear; }
  .gantt-chart-project::before {
    content: "";
    display: block;
    position: absolute;
    position: absolute;
    top: -0.5rem;
    right: 0;
    bottom: -0.5rem;
    left: 0;
    background: rgba(255, 255, 255, 0.2);
    opacity: 0;
    transition: opacity 240ms linear; }
  .gantt-chart-project[data-highlight="true"]::before {
    opacity: 1; }
  .gantt-chart-project:hover {
    z-index: 100; }
  .gantt-chart-project[data-task-down="true"], .gantt-chart-project[data-add-open="true"] {
    z-index: 100; }
    .gantt-chart-project[data-task-down="true"]::before, .gantt-chart-project[data-add-open="true"]::before {
      content: "";
      position: fixed;
      top: -50rem;
      bottom: -50rem;
      left: 0;
      right: 0;
      background: none; }
  .gantt-chart-project .gantt-chart-project__hover {
    pointer-events: none;
    user-select: none;
    background: #1d252c;
    position: fixed;
    left: calc(100% /6);
    margin-left: -5rem;
    top: -8.6875rem;
    z-index: 500;
    box-shadow: 0 0 10px -2px rgba(255, 255, 255, 0.3);
    padding: 1.25rem;
    pointer-events: none; }
    .gantt-chart-project .gantt-chart-project__hover .img {
      flex: 0 0 2.5rem;
      height: 2.5rem;
      width: 2.5rem;
      margin-right: 1rem;
      border-radius: 50%;
      background: #fff;
      border: solid 1px #fff;
      position: relative;
      overflow: hidden; }
      .gantt-chart-project .gantt-chart-project__hover .img img {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 2rem; }
      .gantt-chart-project .gantt-chart-project__hover .img span {
        pointer-events: none;
        user-select: none;
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #1d252c;
        font-weight: 300;
        font-size: 1.125em;
        line-height: 1.125em;
        text-transform: uppercase;
        text-align: center;
        width: 100%; }
    .gantt-chart-project .gantt-chart-project__hover ul li {
      font-size: 0.875em;
      line-height: 1.6em;
      font-weight: 300; }
      .gantt-chart-project .gantt-chart-project__hover ul li.project-name {
        font-size: 1em;
        font-weight: 400; }

.root[data-light-theme="true"] .gantt-chart-project .gantt-chart-project__hover {
  background: #fff;
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2); }
  .root[data-light-theme="true"] .gantt-chart-project .gantt-chart-project__hover .img {
    border: solid 1px #1d252c; }

.gantt-chart-project-link {
  user-select: none;
  position: absolute;
  background: #2a5fbc;
  align-items: center;
  padding: 0 1rem;
  height: 50%;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.4); }
  .gantt-chart-project-link[data-historic="true"] {
    pointer-events: none;
    user-select: none;
    background: #5a7388; }
  .gantt-chart-project-link .img {
    flex: 0 0 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background: #fff;
    position: relative;
    overflow: hidden;
    margin-right: 0.125rem; }
    .gantt-chart-project-link .img img {
      pointer-events: none;
      user-select: none;
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1rem; }
    .gantt-chart-project-link .img span {
      pointer-events: none;
      user-select: none;
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #1d252c;
      font-weight: 300;
      font-size: 0.875em;
      line-height: 0.875em;
      text-transform: uppercase;
      text-align: center;
      width: 100%; }
  .gantt-chart-project-link span {
    font-weight: 700;
    font-size: 0.875em;
    color: #fff;
    background: #2a5fbc;
    white-space: nowrap;
    padding: 0.25rem 0.5rem;
    border-radius: 0 0.25rem 0.25rem 0; }
  .gantt-chart-project-link:hover {
    background: #204a92; }
    .gantt-chart-project-link:hover span {
      background: #204a92; }

.gantt-chart-project[data-hovering="true"] .gantt-chart-project-day .gantt-chart-project-day__active {
  background: #1c3f7d; }
  .gantt-chart-project[data-hovering="true"] .gantt-chart-project-day .gantt-chart-project-day__active::after {
    background: #1c3f7d; }

.gantt-chart-project-task {
  user-select: none;
  height: 45%;
  background: black;
  position: absolute;
  z-index: 20;
  border-radius: 0.25rem;
  bottom: 0;
  transition: opacity 120ms linear;
  cursor: grab;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.4); }
  .gantt-chart-project-task[data-historic="true"] {
    pointer-events: none;
    user-select: none;
    opacity: 0.5; }
  .gantt-chart-project-task[data-reordering="true"] {
    opacity: 0.5; }
  .gantt-chart-project-task .gantt-chart-project-task__info {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 1rem;
    z-index: 200;
    white-space: nowrap;
    align-items: center; }
    .gantt-chart-project-task .gantt-chart-project-task__info .edit-button {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      background: #1d252c;
      position: relative;
      opacity: 0;
      transition: opacity 120ms linear;
      margin-right: 0.25rem; }
      .gantt-chart-project-task .gantt-chart-project-task__info .edit-button:last-child {
        margin-right: 0; }
      .gantt-chart-project-task .gantt-chart-project-task__info .edit-button img {
        pointer-events: none;
        user-select: none;
        width: 0.75rem;
        height: 0.75rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .gantt-chart-project-task .gantt-chart-project-task__info .edit-button:hover {
        background: #313f4b; }
      .gantt-chart-project-task .gantt-chart-project-task__info .edit-button:active {
        background: #3b4c5a; }
  .gantt-chart-project-task .gantt-chart-project-task__label {
    pointer-events: none;
    user-select: none;
    color: #fff;
    font-size: 0.75em;
    font-weight: 700;
    margin-right: 0.625rem; }
  .gantt-chart-project-task:hover .gantt-chart-project-task__info .edit-button {
    opacity: 1; }
  .gantt-chart-project-task[data-last="true"] {
    border-radius: 0 0.25rem 0.25rem 0; }
    .gantt-chart-project-task[data-last="true"]::after {
      display: none; }
  .gantt-chart-project-task[data-task-type="Structure"] {
    background: #ff7f40; }
  .gantt-chart-project-task[data-task-type="Planning"] {
    background: #4ca585; }
  .gantt-chart-project-task[data-task-type="Content"] {
    background: #fecb8b; }
    .gantt-chart-project-task[data-task-type="Content"] .gantt-chart-project-task__label {
      color: #1d252c; }
  .gantt-chart-project-task[data-task-type="Design"] {
    background: #a5e5d9; }
    .gantt-chart-project-task[data-task-type="Design"] .gantt-chart-project-task__label {
      color: #1d252c; }
  .gantt-chart-project-task[data-task-type="Development"] {
    background: #ff9015; }
  .gantt-chart-project-task[data-task-type="Development (Other)"] {
    background: #d2dce5; }
    .gantt-chart-project-task[data-task-type="Development (Other)"] .gantt-chart-project-task__label {
      color: #1d252c; }
  .gantt-chart-project-task[data-task-type="Assessment"] {
    background: #e04403; }
  .gantt-chart-project-task[data-task-type="Testing"] {
    background: #5887da; }
  .gantt-chart-project-task .gantt-chart-project-task__grab-control {
    user-select: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    width: 1.25rem;
    cursor: ew-resize;
    opacity: 0;
    transition: opacity 120ms linear;
    z-index: 500; }
    .gantt-chart-project-task .gantt-chart-project-task__grab-control:hover {
      opacity: 1; }
    .gantt-chart-project-task .gantt-chart-project-task__grab-control.grab-control__back {
      left: 0; }
    .gantt-chart-project-task .gantt-chart-project-task__grab-control.grab-control__forwards {
      right: 0; }
    .gantt-chart-project-task .gantt-chart-project-task__grab-control[data-disabled="true"] {
      display: none;
      cursor: default; }
  .gantt-chart-project-task[data-resizing="backwards"] .gantt-chart-project-task__grab-control.grab-control__back {
    opacity: 1; }
  .gantt-chart-project-task[data-resizing="forwards"] .gantt-chart-project-task__grab-control.grab-control__forwards {
    opacity: 1; }

.gantt-chart-project-task-form.form {
  background: #1d252c;
  position: fixed;
  margin-left: -5rem;
  top: -4rem;
  z-index: 500;
  box-shadow: 0 0 10px -2px rgba(255, 255, 255, 0.3);
  padding: 1.25rem;
  min-width: 18rem; }
  .gantt-chart-project-task-form.form .title {
    margin-bottom: 0.5rem;
    padding-bottom: 0.375rem;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    align-items: center; }
    .gantt-chart-project-task-form.form .title span {
      font-size: 0.875em; }
  .gantt-chart-project-task-form.form .input label {
    flex: 0 0 5rem; }
  .gantt-chart-project-task-form.form .input input, .gantt-chart-project-task-form.form .select .select__value {
    height: 2rem;
    line-height: 2rem; }
  .gantt-chart-project-task-form.form .input .select {
    flex: 1 1 100%; }
  .gantt-chart-project-task-form.form .select .select__list {
    top: 2.5rem; }
  .gantt-chart-project-task-form.form .select .select__list[data-position="top"] {
    top: auto;
    bottom: 2.5rem; }
  .gantt-chart-project-task-form.form .button {
    height: 2rem; }

.root[data-light-theme="true"] .gantt-chart-project-task-form.form {
  background: #fff;
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2); }

.gantt-chart-project__add-task-link {
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  transition: opacity 120ms linear; }
  .gantt-chart-project__add-task-link img {
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%); }
  .gantt-chart-project__add-task-link:hover {
    opacity: 1; }

.root[data-light-theme="true"] .gantt-chart-project__add-task-link {
  background: rgba(255, 229, 0, 0.2); }

body[data-resizing="true"] {
  cursor: ew-resize; }

body[data-reordering="true"] .gantt-chart-project-task {
  cursor: grabbing; }

.gantt-chart__new-project {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 4rem;
  height: 4rem;
  background: #ffe500;
  border-radius: 50%;
  box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.3);
  z-index: 2000; }
  .gantt-chart__new-project img {
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2rem; }
  .gantt-chart__new-project:hover {
    background: #ccb700; }
  .gantt-chart__new-project:active {
    background: #998900; }

.gantt-chart__new-form.form {
  position: fixed;
  background: #1d252c;
  z-index: 5000;
  box-shadow: 0 0 10px -2px rgba(255, 255, 255, 0.3);
  padding: 1.25rem;
  min-width: 21rem; }
  .gantt-chart__new-form.form .title {
    width: 100%;
    margin-bottom: 0.5rem;
    padding-bottom: 0.375rem;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    align-items: center; }
    .gantt-chart__new-form.form .title span {
      font-size: 0.875em; }
  .gantt-chart__new-form.form .input label,
  .gantt-chart__new-form.form .select-with-new-entry .select-with-new-entry__label {
    flex: 0 0 6rem; }
  .gantt-chart__new-form.form .input input, .gantt-chart__new-form.form .select .select__value {
    height: 2rem;
    line-height: 2rem; }
  .gantt-chart__new-form.form .input .select {
    flex: 1 1 100%; }
  .gantt-chart__new-form.form .select .select__list {
    top: 2.5rem; }
  .gantt-chart__new-form.form .select .select__list[data-position="top"] {
    top: auto;
    bottom: 2.5rem; }
  .gantt-chart__new-form.form .select-with-new-entry .select-with-new-entry__add {
    padding-left: 6.5rem; }
  .gantt-chart__new-form.form .add-button {
    align-items: center;
    padding: 0.25rem 0.625rem 0.25rem 0.5rem;
    border-radius: 0.25rem;
    user-select: none; }
    .gantt-chart__new-form.form .add-button img {
      width: 1rem; }
  .gantt-chart__new-form.form .button {
    height: 2rem;
    margin-top: 1.25rem; }
  .gantt-chart__new-form.form .delete-button {
    margin-top: 0.625rem;
    background: #e04403; }
    .gantt-chart__new-form.form .delete-button:hover {
      background: #ae3502; }
    .gantt-chart__new-form.form .delete-button:active {
      background: #7b2502; }
    .gantt-chart__new-form.form .delete-button span {
      color: #fff; }

.root[data-light-theme="true"] .gantt-chart__new-form.form {
  background: #fff;
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.3); }
  .root[data-light-theme="true"] .gantt-chart__new-form.form .title {
    border-bottom: 0 none; }

.gantt-chart__position {
  position: fixed;
  top: 10.625rem;
  right: 2rem;
  background: #1d252c;
  border-radius: 0.25rem;
  z-index: 1000;
  padding: 0.5rem 0.75rem;
  box-shadow: 0, 0, 12px -2px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 120ms linear; }
  .gantt-chart__position[data-visible="true"] {
    opacity: 1; }
  .gantt-chart__position span {
    margin-right: 0.25rem; }
    .gantt-chart__position span:last-child {
      margin-right: 0; }

.root[data-light-theme="true"] .gantt-chart__position span {
  color: #fff; }

/* =========== Primary Palette === */
/* ========== Secondary Palette === */
/* ====== Grey Palette === */
/* ================================================================= Push Auto === */
/* =========================================================== Pseudo Elements === */
/* ========================================================== Responsive Ratio === */
/* ========================================================= Input Placeholder === */
/* ============================================================= Media Queries === */
/* =================================================================== Z Index === */
/* =============================================== Cheat Hardware Acceleration === */
/* ================================================================== Truncate === */
/* ================================================================ Visibility === */
/* ===================================================== Retina Display Images === */
/* ================================================================== Position === */
/* =================================================================== Convert === */
/* ================================================================== Pre View === */
/* ================================================================== In View === */
/* ============================================================== No Highlight === */
@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes slide-in-top {
  0% {
    transform: translate(0, -4rem); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-top {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(0, -4rem); } }

@keyframes slide-in-bottom {
  0% {
    transform: translate(0, 4rem); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-bottom {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(0, 4rem); } }

@keyframes slide-in-left {
  0% {
    transform: translate(-4rem, 0); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-left {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-4rem, 0); } }

@keyframes slide-in-right {
  0% {
    transform: translate(4rem, 0); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-right {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(4rem, 0); } }

@keyframes slide-fade-in-top {
  0% {
    opacity: 0;
    transform: translate(0, -4rem); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-top {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(0, -4rem); } }

@keyframes slide-fade-in-bottom {
  0% {
    opacity: 0;
    transform: translate(0, 4rem); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-bottom {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(0, 4rem); } }

@keyframes slide-fade-in-left {
  0% {
    opacity: 0;
    transform: translate(-4rem, 0); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-left {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(-4rem, 0); } }

@keyframes slide-fade-in-right {
  0% {
    opacity: 0;
    transform: translate(4rem, 0); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-right {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(4rem, 0); } }

@keyframes twist-in {
  0% {
    transform: rotateY(-90deg); }
  100% {
    transform: rotateY(0deg); } }

@keyframes bounce-in {
  0% {
    transform: scale(0); }
  80% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@keyframes bounce-in-vertically-centered {
  0% {
    transform: translate(0, -50%) scale(0); }
  80% {
    transform: translate(0, -50%) scale(1.2); }
  100% {
    transform: translate(0, -50%) scale(1); } }

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(0.25) translate(0, -1.25rem) rotateX(-80deg); }
  100% {
    opacity: 1;
    transform: scale(1) translate(0, 0) rotateX(0); } }

@keyframes scale-fade-in {
  0% {
    opacity: 0;
    transform: scale(0.5); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes scale-fade-out {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.2); } }

@keyframes scale-in-left-to-right {
  0% {
    opacity: 0;
    transform: scaleX(0);
    transform-origin: left center; }
  100% {
    opacity: 1;
    transform: scaleX(1);
    transform-origin: left center; } }

@keyframes nav-collapse {
  0% {
    flex: 0 0 17.5rem;
    width: 17.5rem; }
  100% {
    flex: 0 0 5rem;
    width: 5rem; } }

@keyframes nav-expand {
  0% {
    flex: 0 0 5rem;
    width: 5rem; }
  100% {
    flex: 0 0 17.5rem;
    width: 17.5rem; } }

.clients-add-band, .users-add-band {
  width: 100%;
  padding: 1rem 5rem;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
  .clients-add-band .button, .users-add-band .button {
    max-width: 12rem;
    margin: 0 auto 0 0; }

.clients .table .table-heading.table-heading-img {
  flex: 0 0 6%; }

.clients .table .table-row__td.table-row__td-img {
  flex: 0 0 6%; }

.clients .table .table-row__td .table-row__img {
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  background: #fff; }
  .clients .table .table-row__td .table-row__img img {
    width: 80%; }
  .clients .table .table-row__td .table-row__img span {
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5rem;
    color: #1d252c;
    font-weight: 300;
    text-align: center; }

.clients .table .table-row__td span a {
  text-decoration: underline; }
  .clients .table .table-row__td span a:hover {
    color: #ffe500; }

.add-client.form, .add-user.form {
  padding: 2rem 1.25rem; }
  .add-client.form > .button, .add-user.form > .button {
    max-width: 16rem;
    margin: 2rem auto 0; }

.add-user.form > .flex-row .input {
  flex: 1 1 50%;
  margin-right: 2rem; }
  .add-user.form > .flex-row .input:last-child {
    margin-right: 0; }

/* =========== Primary Palette === */
/* ========== Secondary Palette === */
/* ====== Grey Palette === */
/* ================================================================= Push Auto === */
/* =========================================================== Pseudo Elements === */
/* ========================================================== Responsive Ratio === */
/* ========================================================= Input Placeholder === */
/* ============================================================= Media Queries === */
/* =================================================================== Z Index === */
/* =============================================== Cheat Hardware Acceleration === */
/* ================================================================== Truncate === */
/* ================================================================ Visibility === */
/* ===================================================== Retina Display Images === */
/* ================================================================== Position === */
/* =================================================================== Convert === */
/* ================================================================== Pre View === */
/* ================================================================== In View === */
/* ============================================================== No Highlight === */
.settings .settings__title-band {
  padding: 1.25rem 5rem;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
  .settings .settings__title-band h1 {
    font-size: 1.5rem;
    font-weight: 300; }

.settings .settings__content {
  padding: 2rem 5rem; }

.settings .settings__items {
  flex: 1 1 100%;
  margin-left: 4rem; }

.settings-menu {
  flex: 0 0 20rem; }
  .settings-menu li {
    align-items: center;
    border-radius: 0.25rem;
    padding: 0.625rem 1.25rem;
    cursor: pointer; }
    .settings-menu li:hover, .settings-menu li[aria-selected="true"] {
      background: rgba(255, 255, 255, 0.1); }
    .settings-menu li:active {
      background: rgba(255, 255, 255, 0.2); }
    .settings-menu li img {
      pointer-events: none;
      user-select: none;
      margin-right: 0.5rem; }
    .settings-menu li span {
      pointer-events: none;
      user-select: none; }

.root[data-light-theme="true"] .settings-menu li:hover, .root[data-light-theme="true"] .settings-menu li[aria-selected="true"] {
  background: rgba(0, 0, 0, 0.05); }

.root[data-light-theme="true"] .settings-menu li:active {
  background: rgba(0, 0, 0, 0.1); }

.root[data-light-theme="true"] .settings-menu li img {
  filter: brightness(30%); }

.settings-section {
  max-width: 55rem; }
  .settings-section:last-child {
    margin-bottom: 0; }
  .settings-section .settings-section__title {
    display: block;
    font-size: 1.125em;
    margin-bottom: 1rem; }
  .settings-section .settings-section__rows {
    margin-bottom: 2rem;
    box-shadow: 0 0 6px -2px rgba(0, 0, 0, 0.3); }
  .settings-section .settings-section__row {
    padding: 0.75rem 1.25rem;
    background: #1d252c;
    align-items: center;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
    .settings-section .settings-section__row:first-child {
      border-radius: 0.25rem 0.25rem 0 0; }
    .settings-section .settings-section__row:last-child {
      border-bottom: 0 none;
      border-radius: 0 0 0.25rem 0.25rem; }
    .settings-section .settings-section__row input {
      height: 2rem;
      border-radius: 0.25rem;
      background: #465969;
      color: #fff;
      padding: 0 1rem; }
    .settings-section .settings-section__row .select .select__value {
      height: 2rem;
      line-height: 2rem; }
    .settings-section .settings-section__row .select .select__list {
      top: 2.5rem; }
      .settings-section .settings-section__row .select .select__list[data-position="top"] {
        top: auto; }
    .settings-section .settings-section__row .select .select .select__list[data-position="top"] {
      top: auto;
      bottom: 2.5rem; }
  .settings-section .button {
    margin: 0 0 0 auto;
    max-width: 12rem; }

.root[data-light-theme="true"] .settings-section .settings-section__row {
  background: #fff;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05); }
  .root[data-light-theme="true"] .settings-section .settings-section__row input {
    background: #f2f2f3;
    color: #1d252c; }

html {
  font-size: 1em; }
  html[data-zoom="0.5"] {
    font-size: 0.5em; }
  html[data-zoom="0.75"] {
    font-size: 0.75em; }
  html[data-zoom="1"] {
    font-size: 1em; }
  html[data-zoom="1.25"] {
    font-size: 1.25em; }
  html[data-zoom="1.5"] {
    font-size: 1.5em; }

@media only screen and (min-width: 3800px) {
  html {
    font-size: calc(1em + 0.1vw); }
    html[data-zoom="0.5"] {
      font-size: calc(0.5em + 0.1vw); }
    html[data-zoom="0.75"] {
      font-size: calc(0.75em + 0.1vw); }
    html[data-zoom="1"] {
      font-size: calc(1em + 0.1vw); }
    html[data-zoom="1.25"] {
      font-size: calc(1.25em + 0.1vw); }
    html[data-zoom="1.5"] {
      font-size: calc(1.5em + 0.1vw); } }

::selection,
::-moz-selection {
  background: #ffe500;
  color: #1d252c; }

body {
  font-size: 1em;
  color: #fff;
  background: #fafafa; }

.loading svg {
  width: 1.25rem;
  height: 1.25rem;
  z-index: 30; }

.loading circle {
  fill: none;
  stroke: #fff;
  stroke-width: 9;
  stroke-dasharray: 280;
  stroke-dashoffset: 280;
  stroke-linecap: round;
  animation: loading 2s linear both infinite;
  animation-delay: .34s; }

@keyframes loading {
  0% {
    stroke-dashoffset: 280; }
  100% {
    stroke-dashoffset: -260; } }

/* ==================================================================== Logout === */
.logout .bye {
  color: #fff;
  font-size: 5em;
  font-weight: 900; }

/* ============================================ Splash Screen & Login Elements === */
.splash-screen, .login {
  backface-visibility: hidden;
  perspective: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 400s ease-in-out, transform 400ms ease-in-out;
  background: #27323b;
  perspective: 50rem; }
  .splash-screen .splash-screen__logo, .splash-screen .login__logo, .login .splash-screen__logo, .login .login__logo {
    pointer-events: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-35%, -50%);
    width: 12em;
    height: 12em;
    transition: transform 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .splash-screen .splash-screen__logo img, .splash-screen .login__logo img, .login .splash-screen__logo img, .login .login__logo img {
      width: 100%; }

.splash-screen__logo {
  pointer-events: none;
  opacity: 0;
  animation: fade-in 300ms linear forwards;
  animation-delay: 500ms; }

.login__logo {
  pointer-events: none;
  animation: login-logo-transition 300ms linear forwards;
  animation-delay: 200ms; }

@keyframes login-logo-transition {
  0% {
    transform: translate(-35%, -50%); }
  100% {
    transform: translate(-35%, calc(-50% - 8.5rem)); } }

.login[data-exiting="true"] .login-form {
  animation: scale-fade-out 200ms linear forwards; }

.login[data-exiting="true"] .login__logo {
  animation: login-logo-transition-out 200ms linear forwards; }

@keyframes login-logo-transition-out {
  0% {
    transform: translate(-35%, calc(-50% - 8.5rem));
    opacity: 1; }
  100% {
    transform: translate(-35%, calc(-50% - 8.5rem));
    opacity: 0; } }

.login-form {
  width: 100%;
  max-width: 20rem;
  perspective: 30rem; }
  .login-form .input {
    align-items: center;
    margin: 0 auto 1rem;
    width: 100%;
    opacity: 0;
    animation: scale-in 200ms linear forwards;
    animation-delay: 500ms; }
    .login-form .input:nth-child(2) {
      animation-delay: 600ms; }
    .login-form .input:last-child {
      margin-bottom: 0; }
    .login-form .input .input__wrapper {
      width: 100%;
      border: solid 1px #fff;
      border-radius: 1.25rem; }
    .login-form .input[data-focus="true"] .input__wrapper {
      border: solid 1px #5887da; }
    .login-form .input label {
      display: none; }
    .login-form .input input {
      height: 2.5rem;
      font-size: 1.125em;
      font-weight: 300;
      width: 100%;
      background: transparent;
      color: #fff;
      text-align: center; }
  .login-form .button {
    opacity: 0;
    animation: scale-in 200ms linear forwards;
    animation-delay: 700ms; }
    .login-form .button span {
      font-size: 1.125em; }

.button {
  width: 100%;
  margin: 0 auto;
  background: #ffe500;
  color: #1d252c;
  text-align: center;
  height: 2.5rem;
  border-radius: 1.25rem;
  align-items: center;
  justify-content: center;
  position: relative; }
  .button:hover {
    background: #ccb700; }
  .button:active {
    background: #998900; }
  .button span {
    font-size: 1em; }
  .button .icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0, -50%); }
    .button .icon circle {
      stroke: #1d252c; }
  .button[data-success="true"] .icon {
    transform: scale(0);
    animation: bounce-in-vertically-centered 120ms linear forwards; }

.close-button {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  position: relative; }
  .close-button img {
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.125rem; }
  .close-button:hover {
    background: rgba(255, 255, 255, 0.1); }
  .close-button:active {
    background: rgba(255, 255, 255, 0.2); }

.root[data-light-theme="true"] .close-button img {
  filter: brightness(30%); }

.root[data-light-theme="true"] .close-button:hover {
  background: rgba(0, 0, 0, 0.05); }

.root[data-light-theme="true"] .close-button:active {
  background: rgba(0, 0, 0, 0.1); }

/* ==================================================================== Dialog === */
.dialog {
  backface-visibility: hidden;
  perspective: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 800000;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms ease-in-out; }
  .dialog .dialog__content {
    background: #1d252c;
    position: relative;
    padding: 2rem 3rem;
    min-width: 20rem;
    text-align: center;
    transform: translate(0, -100%);
    transition: transform 200ms ease-in-out; }
    .dialog .dialog__content .dialog__title {
      font-size: 1.25em;
      color: #fff;
      font-weight: 500;
      margin-bottom: 0.92rem; }
    .dialog .dialog__content .dialog__description p {
      font-weight: 300;
      color: #fff;
      margin: 0 auto;
      max-width: 35rem;
      line-height: 1.4em;
      font-size: 0.925em; }
  .dialog .actions__buttons {
    margin-top: 1.5rem;
    width: 100%; }
  .dialog .actions__buttons .button {
    margin: 0 0.5rem;
    width: 100%;
    max-width: 7rem;
    position: relative;
    flex-direction: row-reverse;
    align-items: center; }
    .dialog .actions__buttons .button .icon {
      width: 1.5rem;
      position: static;
      margin-right: 0.5rem;
      transform: none; }
    .dialog .actions__buttons .button[title="No"] {
      background: #e04403;
      color: #fff; }
      .dialog .actions__buttons .button[title="No"]:hover {
        background: #ae3502; }
      .dialog .actions__buttons .button[title="No"]:active {
        background: #ae3502; }
  .dialog[data-open="true"] {
    opacity: 1;
    pointer-events: all; }
    .dialog[data-open="true"] .dialog__content {
      transform: translate(0, 0); }

.notification {
  backface-visibility: hidden;
  perspective: 1000;
  position: fixed;
  top: auto;
  right: auto;
  bottom: 2rem;
  left: 50%;
  z-index: 100000;
  background: black;
  border-radius: 3rem;
  padding: 1rem 2rem;
  opacity: 0;
  transform: translate(-50%, 3rem);
  transition: opacity 120ms ease-in-out, transform 120ms ease-in-out;
  pointer-events: none;
  box-shadow: 0 0 10px -2px rgba(255, 255, 255, 0.2); }
  .notification[data-position="top"] {
    backface-visibility: hidden;
    perspective: 1000;
    position: fixed;
    top: 2rem;
    right: auto;
    bottom: auto;
    left: 50%;
    z-index: 100000; }
  .notification[data-visible="true"] {
    opacity: 1;
    transform: translate(-50%, 0);
    pointer-events: all; }
  .notification p {
    color: #fff;
    font-size: 0.875em;
    font-weight: 500; }

/* ============================================================= Common Inputs === */
.input {
  align-items: center; }
  .input[data-disabled="true"] {
    opacity: 0.5; }
  .input label {
    color: #fff;
    font-size: 0.8125em;
    font-weight: 500;
    margin-right: 0.625rem; }
  .input.text-editor {
    align-items: flex-start; }
    .input.text-editor label {
      padding-top: 1rem; }
  .input .rich-text-editor {
    flex: 1 1 100%; }

::-webkit-calendar-picker-indicator {
  filter: invert(1); }

.root[data-light-theme="true"] ::-webkit-calendar-picker-indicator {
  filter: none; }

.select {
  border-radius: 1.25rem;
  border: solid 1px #fff;
  position: relative;
  min-width: 7.5rem;
  user-select: none; }
  .select:hover {
    background: rgba(255, 255, 255, 0.1); }
  .select:active, .select[data-open="true"] {
    background: rgba(255, 255, 255, 0.2); }
  .select[data-open="true"] {
    border: solid 1px #5887da; }
  .select .select__value {
    width: 100%;
    position: relative;
    color: #fff;
    font-size: 0.8125em;
    font-weight: 500;
    padding: 0 2.25rem 0 1rem;
    height: 2.5rem;
    user-select: none;
    line-height: 2.5rem;
    cursor: pointer;
    text-transform: capitalize; }
    .select .select__value .select__value__dropdown {
      pointer-events: none;
      user-select: none;
      position: absolute;
      width: 0.75rem;
      height: 0.75rem;
      right: 1rem;
      top: 50%;
      transform: translate(0, -50%); }
  .select .select__list {
    pointer-events: none;
    user-select: none;
    position: absolute;
    left: 0;
    top: 3rem;
    background: #13181d;
    min-width: 5rem;
    border-radius: 0.25rem;
    padding: 0.25rem 0;
    opacity: 0;
    transform: scale(0.8);
    transform-origin: left top;
    transition: opacty 300ms linear, transform 300ms linear;
    z-index: 500;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.3);
    max-height: 20vh;
    overflow-y: auto; }
    .select .select__list[data-position="top"] {
      top: auto;
      bottom: 3rem;
      transform-origin: left bottom; }
    .select .select__list[aria-hidden="false"] {
      opacity: 1;
      transform: scale(1);
      transition: opacty 150ms linear, transform 150ms linear;
      pointer-events: all; }
    .select .select__list[aria-hidden="true"] {
      opacity: 0;
      transform: scale(0.8);
      pointer-events: none; }
    .select .select__list li {
      width: 100%;
      padding: 0.75rem 1rem;
      color: #fff;
      font-size: 0.75em;
      font-weight: 400;
      user-select: none;
      cursor: pointer;
      text-transform: capitalize; }
      .select .select__list li:hover {
        background: rgba(255, 255, 255, 0.1); }
      .select .select__list li[aria-selected="true"] {
        background: #5887da; }
      .select .select__list li:active {
        background: rgba(255, 255, 255, 0.2); }
      .select .select__list li[data-disabled="true"] {
        pointer-events: none;
        cursor: default;
        color: #b3b3b3; }
  .select[data-busy="true"] {
    cursor: default; }
    .select[data-busy="true"]:hover {
      background: none; }
    .select[data-busy="true"]:active, .select[data-busy="true"][data-open="true"] {
      background: none; }
    .select[data-busy="true"] .select__value {
      opacity: 0.5;
      cursor: default; }
    .select[data-busy="true"] .loading {
      position: absolute;
      right: -2rem;
      top: 50%;
      transform: translate(0, -50%); }

.root[data-light-theme="true"] .select {
  border: solid 1px #1d252c;
  background: #f2f2f3; }
  .root[data-light-theme="true"] .select:hover {
    background: rgba(0, 0, 0, 0.05); }
  .root[data-light-theme="true"] .select:active, .root[data-light-theme="true"] .select[data-open="true"] {
    background: rgba(0, 0, 0, 0.1); }
  .root[data-light-theme="true"] .select[data-open="true"] {
    border: solid 1px #5887da; }
  .root[data-light-theme="true"] .select .select__value {
    color: #1d252c; }
    .root[data-light-theme="true"] .select .select__value .select__value__dropdown {
      filter: brightness(30%); }
  .root[data-light-theme="true"] .select .select__list {
    background: #fff; }
    .root[data-light-theme="true"] .select .select__list li {
      color: #1d252c; }
      .root[data-light-theme="true"] .select .select__list li:hover {
        background: rgba(0, 0, 0, 0.05); }
      .root[data-light-theme="true"] .select .select__list li[aria-selected="true"] {
        background: #5887da;
        color: #fff;
        font-weight: 700; }
      .root[data-light-theme="true"] .select .select__list li:active {
        background: rgba(0, 0, 0, 0.1); }
      .root[data-light-theme="true"] .select .select__list li[data-disabled="true"] {
        color: #5a7388; }

.switch-toggle {
  height: 2rem;
  position: relative;
  width: 2.5rem;
  z-index: 10;
  cursor: pointer; }
  .switch-toggle .switch-toggle__rail {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    pointer-events: none;
    user-select: none;
    transform: translate(0, -50%);
    width: 100%;
    height: 1rem;
    border-radius: 1.25rem;
    background: #465969;
    transition: all 120ms linear; }
  .switch-toggle .switch-toggle__dot {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 0;
    z-index: 50;
    pointer-events: none;
    user-select: none;
    height: 1.25rem;
    width: 1.25rem;
    transform: translate(0, -50%);
    background: #91a5b7;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    transition: all 120ms linear; }
  .switch-toggle[data-on="true"] .switch-toggle__rail {
    background: #6d96df; }
  .switch-toggle[data-on="true"] .switch-toggle__dot {
    background: #c0d2f1;
    transform: translate(100%, -50%); }

.root[data-light-theme="true"] .switch-toggle .switch-toggle__rail {
  background: #e1e1e1; }

.root[data-light-theme="true"] .switch-toggle .switch-toggle__dot {
  background: #fafafa; }

.root[data-light-theme="true"] .switch-toggle[data-on="true"] .switch-toggle__rail {
  background: #6d96df; }

.root[data-light-theme="true"] .switch-toggle[data-on="true"] .switch-toggle__dot {
  background: #c0d2f1;
  transform: translate(100%, -50%); }

.form .input {
  margin-bottom: 0.625rem; }
  .form .input label {
    flex: 0 0 7.5rem; }
  .form .input .input__wrapper {
    flex: 1 1 100%;
    border-radius: 1.25rem;
    border: solid 1px #fff;
    position: relative; }
    .form .input .input__wrapper[data-name="price"] input {
      padding-left: 1.625rem; }
    .form .input .input__wrapper[data-name="price"]::before {
      content: "";
      display: block;
      position: absolute;
      content: "£";
      color: #fff;
      font-size: 0.8125em;
      left: 1rem;
      top: 50%;
      transform: translate(0, -50%); }
  .form .input[data-focus="true"] .input__wrapper {
    border: solid 1px #5887da; }
  .form .input input {
    width: 100%;
    height: 2.5rem;
    padding: 0 1rem;
    background: none;
    color: #fff;
    font-size: 0.8125em;
    border-radius: 1.25rem; }
  .form .input.file .file-input {
    position: relative;
    align-items: center;
    height: 2.5rem; }
    .form .input.file .file-input input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0; }
    .form .input.file .file-input .button {
      flex: 0 0 8rem;
      margin-right: 0.625rem;
      border-radius: 0.25rem;
      background: #5887da;
      color: #fff;
      font-size: 0.875em;
      font-weight: 700;
      height: 1.875rem; }
      .form .input.file .file-input .button:hover {
        background: #2e69d1; }
    .form .input.file .file-input .file-input__files {
      flex: 1 1 100%; }
      .form .input.file .file-input .file-input__files span {
        white-space: nowrap; }

.root[data-light-theme="true"] .form .input label {
  color: #1d252c; }

.root[data-light-theme="true"] .form .input .input__wrapper {
  border: solid 1px #1d252c;
  background: #f2f2f3; }
  .root[data-light-theme="true"] .form .input .input__wrapper input {
    color: #1d252c; }

.root[data-light-theme="true"] .form .input[data-focus="true"] .input__wrapper {
  border: solid 1px #5887da; }

.select-with-new-entry .select-with-new-entry__section {
  margin-bottom: 0.625rem;
  align-items: center; }

.select-with-new-entry .select-with-new-entry__label {
  flex: 0 0 7.5rem;
  font-size: 0.8125em;
  margin-right: 0.5rem; }

.select-with-new-entry .select {
  flex: 1 1 100%;
  width: 100%; }

.select-with-new-entry .select-with-new-entry__add {
  padding-left: 8rem;
  margin-bottom: 1rem; }

.select-with-new-entry .select-with-new-entry__form {
  position: relative; }
  .select-with-new-entry .select-with-new-entry__form .select-with-new-entry__form__close {
    position: absolute;
    left: -2.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%; }
    .select-with-new-entry .select-with-new-entry__form .select-with-new-entry__form__close:hover {
      background: rgba(255, 255, 255, 0.1); }
    .select-with-new-entry .select-with-new-entry__form .select-with-new-entry__form__close:active {
      background: rgba(255, 255, 255, 0.2); }
    .select-with-new-entry .select-with-new-entry__form .select-with-new-entry__form__close img {
      pointer-events: none;
      user-select: none; }
  .select-with-new-entry .select-with-new-entry__form input {
    width: 100%;
    background: none;
    padding: 0;
    height: 2rem;
    border-bottom: solid 1px #fff;
    font-size: 0.75em;
    color: #fff;
    opacity: 0;
    animation: scale-in-left-to-right 120ms linear forwards; }
    .select-with-new-entry .select-with-new-entry__form input:focus {
      border-bottom: solid 1px #5887da; }

.root[data-light-theme="true"] .select-with-new-entry .select-with-new-entry__form .select-with-new-entry__form__close img {
  filter: brightness(30%); }

.root[data-light-theme="true"] .select-with-new-entry .select-with-new-entry__form .select-with-new-entry__form__close:hover {
  background: rgba(0, 0, 0, 0.1); }

.root[data-light-theme="true"] .select-with-new-entry .select-with-new-entry__form .select-with-new-entry__form__close:active {
  background: rgba(0, 0, 0, 0.2); }

.root[data-light-theme="true"] .select-with-new-entry .select-with-new-entry__form input {
  background: #f2f3f3;
  border-bottom: solid 1px #f2f2f3;
  color: #1d252c;
  padding: 0 0.625rem; }
  .root[data-light-theme="true"] .select-with-new-entry .select-with-new-entry__form input:focus {
    border-bottom: solid 1px #5887da; }

.checkbox {
  padding: 0.625rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  user-select: none;
  align-items: center; }
  .checkbox:hover {
    background: rgba(255, 255, 255, 0.1); }
  .checkbox:active {
    background: rgba(255, 255, 255, 0.2); }
  .checkbox .checkbox__box {
    pointer-events: none;
    border-radius: 0.25rem;
    border: solid 1px #fff;
    width: 1.25rem;
    height: 1.25rem;
    position: relative;
    margin-right: 0.5rem; }
    .checkbox .checkbox__box img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.875rem; }
  .checkbox .checkbox__label {
    pointer-events: none;
    font-size: 0.8125rem; }

.add-button {
  align-items: center;
  padding: 0.375rem 0.625rem 0.375rem 0.5rem;
  border-radius: 0.25rem;
  user-select: none; }
  .add-button img {
    pointer-events: none;
    user-select: none; }
  .add-button:hover {
    background: rgba(255, 255, 255, 0.1); }
  .add-button:active {
    background: rgba(255, 255, 255, 0.2); }
  .add-button span {
    pointer-events: none;
    user-select: none;
    color: #ffe500;
    font-size: 0.8125em; }

.root[data-light-theme="true"] .add-button:hover {
  background: rgba(0, 0, 0, 0.05); }

.root[data-light-theme="true"] .add-button:active {
  background: rgba(0, 0, 0, 0.1); }

.root[data-light-theme="true"] .add-button span {
  color: #5887da; }

.remove-button {
  width: 2rem;
  height: 2rem;
  border-radius: 50%; }
  .remove-button:hover {
    background: rgba(255, 255, 255, 0.1); }
  .remove-button:active {
    background: rgba(255, 255, 255, 0.2); }
  .remove-button img {
    pointer-events: none;
    user-select: none;
    width: 1.5rem; }

.root[data-light-theme="true"] .remove-button:hover {
  background: rgba(0, 0, 0, 0.05); }

.root[data-light-theme="true"] .remove-button:active {
  background: rgba(0, 0, 0, 0.1); }

.root[data-light-theme="true"] .remove-button img {
  filter: brightness(30%); }

.rich-text-editor {
  border-radius: 0.5rem;
  border: solid 1px #fff; }
  .rich-text-editor .rich-text-editor__controls {
    padding: 0.625rem 1rem;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
  .rich-text-editor .rich-text-editor__editor {
    padding: 0.625rem 1rem 1.25rem;
    font-weight: 300;
    line-height: 1.6em;
    max-height: 12rem;
    overflow-y: scroll; }
    .rich-text-editor .rich-text-editor__editor span {
      font-weight: inherit;
      line-height: 1.6em; }
    .rich-text-editor .rich-text-editor__editor li {
      line-height: 1.6em; }
      .rich-text-editor .rich-text-editor__editor li::before {
        line-height: 1.6em; }
    .rich-text-editor .rich-text-editor__editor .public-DraftEditorPlaceholder-inner {
      line-height: 1.6em; }
    .rich-text-editor .rich-text-editor__editor .public-DraftEditorPlaceholder-hasFocus {
      opacity: 0; }
    .rich-text-editor .rich-text-editor__editor h2 {
      font-size: 1.5em;
      font-weight: 500;
      line-height: 1.6em; }

.rich-text-editor-control-button {
  border-radius: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  margin-right: 0.25rem; }
  .rich-text-editor-control-button:hover {
    background: rgba(255, 255, 255, 0.1); }
  .rich-text-editor-control-button[aria-selected="true"], .rich-text-editor-control-button:active {
    background: rgba(255, 255, 255, 0.2); }
  .rich-text-editor-control-button img {
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    width: 1.125rem;
    transform: translate(-50%, -50%); }

.react-datepicker-popper {
  z-index: 5000; }

.date-picker {
  align-items: center; }
  .date-picker .react-datepicker-wrapper {
    flex: 1 1 100%; }
  .date-picker .date-picker__input {
    align-items: center; }
  .date-picker .date-picker__value {
    flex: 1 1 100%;
    align-items: center;
    border: solid 1px #fff;
    height: 2rem;
    border-radius: 2rem;
    padding: 0 1rem; }
    .date-picker .date-picker__value:hover {
      background: rgba(255, 255, 255, 0.1); }
    .date-picker .date-picker__value:active {
      background: rgba(255, 255, 255, 0.2); }
    .date-picker .date-picker__value span {
      color: #fff;
      font-size: 0.8125em; }
    .date-picker .date-picker__value img {
      pointer-events: none;
      user-select: none;
      width: 1.125rem; }
  .date-picker .date-picker__clear,
  .date-picker .date-picker__spacer {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin-left: 0.25rem; }
    .date-picker .date-picker__clear img,
    .date-picker .date-picker__spacer img {
      pointer-events: none;
      user-select: none;
      width: 1rem; }
  .date-picker .date-picker__spacer {
    pointer-events: none;
    user-select: none; }
  .date-picker .date-picker__clear {
    background: #090b0d; }
    .date-picker .date-picker__clear:hover {
      background: #13181d; }
  .date-picker[data-disabled="true"] .date-picker__value {
    cursor: default; }
    .date-picker[data-disabled="true"] .date-picker__value:hover {
      background: none; }
    .date-picker[data-disabled="true"] .date-picker__value:active {
      background: none; }

.date-picker-calendar {
  box-shadow: 0 0 10px -2px rgba(255, 255, 255, 0.2); }
  .date-picker-calendar .react-datepicker__header {
    background: #13181d;
    border-radius: 0.25rem 0.25rem 0 0;
    border-bottom: solid 1px rgba(255, 255, 255, 0.35);
    padding: 1rem 1rem 0.625rem; }
    .date-picker-calendar .react-datepicker__header .date-picker__header {
      justify-content: center;
      align-items: center;
      margin-bottom: 0.625rem; }
      .date-picker-calendar .react-datepicker__header .date-picker__header button {
        width: 2rem;
        height: 2rem;
        border-radius: 50%; }
        .date-picker-calendar .react-datepicker__header .date-picker__header button:hover {
          background: rgba(255, 255, 255, 0.1); }
        .date-picker-calendar .react-datepicker__header .date-picker__header button:active {
          background: rgba(255, 255, 255, 0.2); }
      .date-picker-calendar .react-datepicker__header .date-picker__header span {
        margin: 0 auto;
        font-size: 0.875em; }
    .date-picker-calendar .react-datepicker__header .react-datepicker__day-names {
      display: flex;
      flex-direction: row; }
      .date-picker-calendar .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
        flex: 1 1 100%;
        font-size: 0.875em;
        text-align: center; }
  .date-picker-calendar .react-datepicker__month {
    padding: 0.625rem 1rem;
    background: #13181d;
    border-radius: 0 0 0.25rem 0.25rem; }
    .date-picker-calendar .react-datepicker__month .react-datepicker__week {
      display: flex;
      flex-direction: row; }
      .date-picker-calendar .react-datepicker__month .react-datepicker__week .react-datepicker__day {
        width: 2rem;
        flex: 1 1 100%;
        font-size: 0.875em;
        text-align: center;
        border-radius: 0.25rem;
        height: 2rem;
        padding: 0.625rem 0 0;
        cursor: pointer;
        outline: none; }
        .date-picker-calendar .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover {
          background: rgba(255, 255, 255, 0.1); }
        .date-picker-calendar .react-datepicker__month .react-datepicker__week .react-datepicker__day:active {
          background: rgba(255, 255, 255, 0.2); }
        .date-picker-calendar .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--today {
          background: #ff9015; }
        .date-picker-calendar .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected {
          background: #5887da; }
        .date-picker-calendar .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day[aria-disabled="true"] {
          cursor: default;
          opacity: 0.25; }
          .date-picker-calendar .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day[aria-disabled="true"]:hover {
            background: none; }
          .date-picker-calendar .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day[aria-disabled="true"]:active {
            background: none; }

/* ====================================================================== Root === */
.root {
  backface-visibility: hidden;
  perspective: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: #27323b;
  align-items: stretch; }

.root[data-light-theme="true"] {
  background: #f2f3f3;
  color: #1d252c; }

.root-logo {
  pointer-events: none;
  user-select: none;
  animation: fade-out 300ms linear forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -50%);
  width: 12em;
  height: 12em;
  transition: transform 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.root[data-loaded="true"] .root-logo {
  display: none; }

.nav {
  position: relative;
  flex: 0 0 17.5rem;
  background: #13181d;
  height: 100%;
  animation: slide-fade-in-left 300ms linear forwards;
  animation-delay: 600ms;
  opacity: 0;
  z-index: 100000; }
  .nav .nav__logo-space {
    flex: 0 0 4rem;
    height: 4rem;
    width: 100%;
    border-bottom: solid 1px rgba(255, 255, 255, 0.3); }
    .nav .nav__logo-space img {
      pointer-events: none;
      user-select: none;
      width: 100%;
      max-width: 5rem;
      transition: all 120ms linear; }
  .nav[data-collapsed="true"] .nav__logo-space img {
    max-width: 2rem; }
  .nav .nav__menu {
    padding: 1rem;
    width: 100%;
    flex: 1 1 100%; }
    .nav .nav__menu .nav__menu__item {
      width: 100%;
      padding: 0.625rem 1rem;
      border-radius: 0.25rem;
      align-items: center; }
      .nav .nav__menu .nav__menu__item:hover {
        background: rgba(255, 255, 255, 0.1); }
      .nav .nav__menu .nav__menu__item:active {
        background: rgba(255, 255, 255, 0.2); }
      .nav .nav__menu .nav__menu__item img {
        pointer-events: none;
        user-select: none;
        width: 1.75rem;
        height: 1.75rem;
        margin-right: 0.5rem; }
      .nav .nav__menu .nav__menu__item span {
        pointer-events: none;
        user-select: none;
        color: #fff;
        font-size: 0.875em;
        font-weight: 500;
        white-space: nowrap;
        transition: opacity 120ms linear; }
      .nav .nav__menu .nav__menu__item[aria-current="page"] span {
        color: #ffe500; }
    .nav .nav__menu .nav__menu__sub {
      width: 100%;
      padding-top: 1.25rem;
      padding-left: 3.25rem;
      padding-bottom: 1.25rem;
      margin-bottom: 1.25rem;
      border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
      .nav .nav__menu .nav__menu__sub .nav__menu__sub__item {
        font-size: 0.875em;
        padding: 0.5rem 0; }
        .nav .nav__menu .nav__menu__sub .nav__menu__sub__item:hover, .nav .nav__menu .nav__menu__sub .nav__menu__sub__item[aria-current="page"] {
          color: #ffe500; }
        .nav .nav__menu .nav__menu__sub .nav__menu__sub__item:active {
          color: #e6ce00; }
  .nav .nav__collapse-toggle {
    position: absolute;
    top: 0;
    right: -0.5rem;
    bottom: 0;
    left: auto;
    z-index: 50;
    background: rgba(255, 255, 255, 0.2);
    width: 1rem;
    cursor: pointer;
    opacity: 0;
    transition: opacity 120ms linear; }
    .nav .nav__collapse-toggle:hover {
      opacity: 0.4; }
    .nav .nav__collapse-toggle:active {
      opacity: 0.5; }
  .nav .nav__help-menu {
    margin-top: auto;
    width: 100%; }
    .nav .nav__help-menu .nav__help-menu__menu {
      position: fixed;
      bottom: 2rem;
      background: #1d252c;
      left: 17rem;
      min-width: 16rem;
      box-shadow: 0 0 8px -2px rgba(255, 255, 255, 0.3);
      border-radius: 0.25rem;
      padding: 1rem 0; }
      .nav .nav__help-menu .nav__help-menu__menu .title {
        display: block;
        line-height: 1.6em;
        padding: 0 0.625rem;
        font-size: 0.75em;
        text-transform: uppercase;
        opacity: 0.75; }
      .nav .nav__help-menu .nav__help-menu__menu ul {
        width: 100%;
        margin-bottom: 0.625rem; }
        .nav .nav__help-menu .nav__help-menu__menu ul:last-child {
          margin-bottom: 0; }
      .nav .nav__help-menu .nav__help-menu__menu a {
        width: 100%;
        align-items: center;
        padding: 0.375rem 0.625rem; }
        .nav .nav__help-menu .nav__help-menu__menu a:hover {
          background: rgba(255, 255, 255, 0.1); }
        .nav .nav__help-menu .nav__help-menu__menu a:active {
          background: rgba(255, 255, 255, 0.2); }
        .nav .nav__help-menu .nav__help-menu__menu a span {
          font-size: 0.875em;
          margin-right: 0.25rem; }
        .nav .nav__help-menu .nav__help-menu__menu a img {
          pointer-events: none;
          user-select: none;
          width: 1.125rem;
          position: relative;
          top: -0.0625rem; }

.root[data-light-theme="true"] .nav {
  background: #1d252c; }

.root[data-loaded="true"] .nav {
  opacity: 1;
  animation-delay: 0s; }

.root[data-loaded="true"] .nav[data-animation-flag="true"] {
  animation: nav-expand 240ms linear forwards; }

.root[data-loaded="true"] .nav[data-collapsed="true"] {
  animation: nav-collapse 240ms linear forwards;
  opacity: 1; }
  .root[data-loaded="true"] .nav[data-collapsed="true"] .nav__logo-space img {
    max-width: 1.5em; }
  .root[data-loaded="true"] .nav[data-collapsed="true"] .nav__menu__item {
    padding: 0.625rem; }
    .root[data-loaded="true"] .nav[data-collapsed="true"] .nav__menu__item img {
      margin: 0 auto; }
    .root[data-loaded="true"] .nav[data-collapsed="true"] .nav__menu__item span {
      opacity: 0;
      pointer-events: none; }
  .root[data-loaded="true"] .nav[data-collapsed="true"] .nav__help-menu__menu {
    left: 5.25rem; }

.root__main {
  flex: 1 1 100%;
  position: relative; }

.top-bar {
  width: 100%;
  align-items: center;
  flex: 0 0 4rem;
  height: 4rem;
  background: #1d252c;
  padding: 0 5rem;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  opacity: 0;
  animation: slide-fade-in-right 300ms linear forwards;
  animation-delay: 600ms;
  position: relative;
  z-index: 9000; }
  .top-bar .top-bar__welcome span {
    color: #fff;
    font-size: 1.5em;
    font-weight: 300; }
    .top-bar .top-bar__welcome span b {
      font-weight: 500; }
  .top-bar .top-bar__nav {
    align-items: center; }
  .top-bar .top-bar__nav__link {
    align-items: center;
    height: 2rem;
    padding: 0 0.875rem 0 0.5rem;
    border-radius: 0.25rem; }
    .top-bar .top-bar__nav__link:hover, .top-bar .top-bar__nav__link[aria-current="page"] {
      background: rgba(255, 255, 255, 0.1); }
    .top-bar .top-bar__nav__link:active {
      background: rgba(255, 255, 255, 0.2); }
    .top-bar .top-bar__nav__link img {
      pointer-events: none;
      user-select: none;
      width: 1.5rem;
      margin-right: 0.25rem; }
    .top-bar .top-bar__nav__link span {
      pointer-events: none;
      user-select: none;
      color: #fff;
      font-size: 0.875em;
      font-weight: 300; }

.root[data-light-theme="true"] .top-bar {
  background: #fff;
  border-bottom: solid 1px #fff; }
  .root[data-light-theme="true"] .top-bar .top-bar__welcome span {
    color: #1d252c; }
    .root[data-light-theme="true"] .top-bar .top-bar__welcome span b {
      font-weight: 500;
      color: #4ca585; }
  .root[data-light-theme="true"] .top-bar .top-bar__nav__link span {
    color: #1d252c; }
  .root[data-light-theme="true"] .top-bar .top-bar__nav__link:hover, .root[data-light-theme="true"] .top-bar .top-bar__nav__link[aria-current="page"] {
    background: rgba(0, 0, 0, 0.05); }
  .root[data-light-theme="true"] .top-bar .top-bar__nav__link:active {
    background: rgba(0, 0, 0, 0.1); }

.top-bar-notifications {
  position: relative;
  margin-right: 1rem; }
  .top-bar-notifications .top-bar-notifications__icon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor: pointer;
    position: relative; }
    .top-bar-notifications .top-bar-notifications__icon span {
      position: absolute;
      top: 0;
      right: 0;
      bottom: auto;
      left: auto;
      z-index: 50;
      pointer-events: none;
      user-select: none;
      background: #ffe500;
      color: #1d252c;
      width: 1rem;
      height: 1rem;
      line-height: 1rem;
      text-align: center;
      font-size: 0.75em;
      font-weight: 500;
      border-radius: 0.125rem; }
    .top-bar-notifications .top-bar-notifications__icon img {
      pointer-events: none;
      user-select: none;
      width: 1.5rem;
      height: 1.5rem; }
    .top-bar-notifications .top-bar-notifications__icon:hover {
      background: rgba(255, 255, 255, 0.1); }
    .top-bar-notifications .top-bar-notifications__icon:active {
      background: rgba(255, 255, 255, 0.2); }

.page {
  opacity: 0;
  animation: slide-fade-in-bottom 300ms linear forwards;
  animation-delay: 600ms;
  flex: 1 1 100%;
  width: 100%;
  overflow-y: auto; }

.root[data-loaded="true"] .page {
  animation-duration: 200ms;
  animation-delay: 0ms; }

.page::-webkit-scrollbar,
.thin-scroll::-webkit-scrollbar,
.modal::-webkit-scrollbar {
  width: 6px; }

.page::-webkit-scrollbar-track,
.thin-scroll::-webkit-scrollbar-track,
.modal::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #465969; }

.page::-webkit-scrollbar-thumb,
.thin-scroll::-webkit-scrollbar-thumb,
.modal::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 10px; }

.root[data-light-theme="true"] .page::-webkit-scrollbar-track,
.root[data-light-theme="true"] .thin-scroll::-webkit-scrollbar-track,
.root[data-light-theme="true"] .modal::-webkit-scrollbar-track {
  background: #e1e1e1; }

.root[data-light-theme="true"] .page::-webkit-scrollbar-thumb,
.root[data-light-theme="true"] .thin-scroll::-webkit-scrollbar-thumb,
.root[data-light-theme="true"] .modal::-webkit-scrollbar-thumb {
  background: #fafafa; }

/* ===================================================================== Modal === */
.modal {
  backface-visibility: hidden;
  perspective: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 80000;
  background: rgba(0, 0, 0, 0.75);
  overflow-y: auto;
  opacity: 0;
  animation: fade-in 300ms linear forwards;
  animation-delay: 600ms; }
  .modal .modal__view {
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 50rem;
    animation: slide-fade-in-bottom 250ms linear; }
    .modal .modal__view .modal__top {
      align-items: center;
      justify-content: space-between;
      background: #1d252c;
      border-radius: 0.5rem 0.5rem 0 0;
      border-bottom: solid 1px rgba(255, 255, 255, 0.2);
      padding: 1rem 1.25rem; }
      .modal .modal__view .modal__top .modal__title {
        align-items: center; }
        .modal .modal__view .modal__top .modal__title img {
          margin-right: 0.5rem; }
        .modal .modal__view .modal__top .modal__title span {
          color: #fff;
          font-weight: 300;
          font-size: 1.25em; }
      .modal .modal__view .modal__top .back-button {
        width: 2rem;
        height: 2rem;
        border-radius: 50%; }
        .modal .modal__view .modal__top .back-button:hover {
          background: rgba(255, 255, 255, 0.1); }
        .modal .modal__view .modal__top .back-button:active {
          background: rgba(255, 255, 255, 0.2); }
    .modal .modal__view .modal__content {
      background: #1d252c;
      border-radius: 0 0 0.5rem 0.5rem; }

.root[data-loaded="true"] .modal {
  animation-duration: 200ms;
  animation-delay: 0ms; }

.root[data-light-theme="true"] .modal .modal__view .modal__top {
  background: #fff;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2); }
  .root[data-light-theme="true"] .modal .modal__view .modal__top .modal__title img {
    filter: brightness(30%); }
  .root[data-light-theme="true"] .modal .modal__view .modal__top .modal__title span {
    color: #1d252c; }
  .root[data-light-theme="true"] .modal .modal__view .modal__top .back-button:hover {
    background: rgba(0, 0, 0, 0.05); }
  .root[data-light-theme="true"] .modal .modal__view .modal__top .back-button:active {
    background: rgba(0, 0, 0, 0.1); }
  .root[data-light-theme="true"] .modal .modal__view .modal__top .back-button img {
    filter: brightness(30%); }

.root[data-light-theme="true"] .modal .modal__view .modal__content {
  background: #fff; }

/* ================================================================== Tables === */
.filter-bar {
  position: relative;
  z-index: 500;
  padding: 1.25rem 5rem; }
  .filter-bar .filter-bar__section {
    align-items: center; }
    .filter-bar .filter-bar__section .filter-bar__label {
      font-size: 0.8125em;
      color: #fff;
      margin-right: 0.5rem; }
    .filter-bar .filter-bar__section .select {
      margin-right: 0.5rem;
      min-width: 6rem; }

.root[data-light-theme="true"] .filter-bar .filter-bar__label {
  color: #1d252c; }

.filter-bar-search {
  border: solid 1px #fff;
  height: 2.5rem;
  border-radius: 1.25rem;
  position: relative;
  width: 100%;
  min-width: 15rem; }
  .filter-bar-search[data-focus="true"] {
    border: solid 1px #5887da; }
  .filter-bar-search input {
    background: none;
    width: 100%;
    height: 2.5rem;
    padding: 0 1rem 0 3rem;
    color: #fff;
    font-weight: 400;
    font-size: 0.8125em; }
  .filter-bar-search .filter-bar-search__icon {
    pointer-events: none;
    user-select: none;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translate(0, -50%); }

.root[data-light-theme="true"] .filter-bar-search {
  border: solid 1px #1d252c; }
  .root[data-light-theme="true"] .filter-bar-search input {
    color: #1d252c; }
  .root[data-light-theme="true"] .filter-bar-search .filter-bar-search__icon {
    filter: brightness(30%); }

.table {
  padding: 0 5rem; }
  .table .table__head {
    border-top: solid 1px rgba(255, 255, 255, 0.2);
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    align-items: center; }
  .table .table__empty {
    color: #fff;
    font-size: 1em;
    font-weight: 300;
    padding: 3.5rem 1rem 2.25rem;
    width: 100%;
    text-align: center; }

.root[data-light-theme="true"] .table .table__head {
  background: #5887da;
  border-radius: 0.25rem 0.25rem 0 0;
  border-top: 0 none;
  border-bottom: 0 none;
  box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.2); }

.root[data-light-theme="true"] .table .table__body {
  box-shadow: 0 0 8px -2px rgba(0, 0, 0, 0.2); }

.table-heading {
  flex: 1 1 13%;
  padding: 0.5rem 1rem;
  align-items: center; }
  .table-heading[data-sortable="true"] {
    cursor: pointer; }
    .table-heading[data-sortable="true"]:hover {
      background: rgba(255, 255, 255, 0.1); }
    .table-heading[data-sortable="true"]:active {
      background: rgba(255, 255, 255, 0.2); }
  .table-heading span {
    pointer-events: none;
    user-select: none;
    color: #fff;
    margin-right: 0.25rem;
    font-weight: 400;
    font-size: 0.875em; }
  .table-heading .icon {
    pointer-events: none;
    user-select: none;
    opacity: 0.5; }
    .table-heading .icon img {
      width: 1.5rem; }
  .table-heading[aria-selected="true"] .icon {
    opacity: 1; }

.root[data-light-theme="true"] .table-heading[data-sortable="true"]:hover {
  background: rgba(0, 0, 0, 0.05); }

.root[data-light-theme="true"] .table-heading[data-sortable="true"]:active {
  background: rgba(0, 0, 0, 0.1); }

.root[data-light-theme="true"] .table-heading span {
  color: #fff; }

.table-row {
  background: #1d252c;
  align-items: center;
  animation: slide-fade-in-left 200ms linear; }
  .table-row:nth-child(even) {
    background: #27323b; }
  .table-row .table-row__td {
    flex: 1 1 13%;
    padding: 1rem; }
    .table-row .table-row__td.flex-row {
      align-items: center; }
    .table-row .table-row__td span {
      color: #fff;
      font-size: 0.8125em;
      font-weight: 400; }
    .table-row .table-row__td[data-ok="true"] span {
      color: #4ca585;
      margin-left: 0.25rem; }
    .table-row .table-row__td[data-ok="false"] span {
      color: #e04403;
      margin-left: 0.25rem; }
    .table-row .table-row__td .table-row__img {
      width: 100%;
      height: 0;
      padding-top: 100%;
      overflow: hidden;
      position: relative;
      background: #fafafa;
      border-radius: 0.25rem; }
      .table-row .table-row__td .table-row__img img {
        pointer-events: none;
        user-select: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 105%;
        transform: translate(-50%, -50%); }
        .table-row .table-row__td .table-row__img img[data-error="true"] {
          width: 2rem;
          opacity: 0.75; }
    .table-row .table-row__td .table-row__action {
      width: 2rem;
      height: 2rem;
      border-radius: 0.25rem;
      background: #5887da;
      margin-right: 0.5rem; }
      .table-row .table-row__td .table-row__action:hover {
        background: #2e69d1; }
      .table-row .table-row__td .table-row__action:active {
        background: #2554a7; }
      .table-row .table-row__td .table-row__action[title="Delete"] {
        background: #e04403;
        border: 0 none; }
        .table-row .table-row__td .table-row__action[title="Delete"]:hover {
          background: #ae3502; }
        .table-row .table-row__td .table-row__action[title="Delete"]:active {
          background: #7b2502; }
      .table-row .table-row__td .table-row__action img {
        pointer-events: none;
        user-select: none; }

.root[data-light-theme="true"] .table-row {
  background: #fff; }
  .root[data-light-theme="true"] .table-row:nth-child(even) {
    background: #f2f2f3; }
  .root[data-light-theme="true"] .table-row:last-child {
    border-radius: 0 0 0.25rem 0.25rem; }
  .root[data-light-theme="true"] .table-row .table-row__td span {
    color: #1d252c; }

.table-row__td.table-row__td-img,
.table-heading.table-heading-img {
  flex: 0 0 9%; }

.page[data-transition="true"] .table-row {
  animation: slide-fade-out-right 200ms linear; }

.pager {
  padding: 1.25rem 5rem 5rem;
  align-items: center; }
  .pager .products-pager__label {
    color: #fff;
    font-size: 0.8125em; }
  .pager .pager__button {
    color: #fff;
    min-width: 2rem;
    height: 2rem;
    border-radius: 0.25rem;
    text-align: center;
    border: solid 1px #fff;
    margin: 0 0.25rem;
    align-items: center;
    user-select: none; }
    .pager .pager__button:hover {
      background: rgba(255, 255, 255, 0.1); }
    .pager .pager__button:active {
      background: rgba(255, 255, 255, 0.2); }
    .pager .pager__button[aria-selected="true"] {
      background: #ffe500;
      border: solid 1px #ffe500;
      color: #1d252c; }
    .pager .pager__button[title="Previous Page"] {
      padding-right: 1rem; }
    .pager .pager__button[title="Next Page"] {
      padding-left: 1rem; }

.root[data-light-theme="true"] .pager .products-pager__label {
  color: #1d252c; }

.root[data-light-theme="true"] .pager .pager__button {
  color: #1d252c;
  border: solid 1px #1d252c; }
  .root[data-light-theme="true"] .pager .pager__button:hover {
    background: rgba(0, 0, 0, 0.05); }
  .root[data-light-theme="true"] .pager .pager__button:active {
    background: rgba(0, 0, 0, 0.1); }
  .root[data-light-theme="true"] .pager .pager__button[aria-selected="true"] {
    background: #ffe500;
    border: solid 1px #ffe500;
    color: #1d252c; }
  .root[data-light-theme="true"] .pager .pager__button img {
    filter: brightness(30%); }

.create-modal {
  padding: 1.25rem 1.25rem 2rem; }

.create-modal .form > p {
  font-size: 0.875em;
  line-height: 1.6em;
  margin-bottom: 2rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.75); }

.create-modal .form > .flex-row:not(.button) {
  align-items: stretch; }

.create-modal .form .button {
  max-width: 15rem;
  margin: 2rem auto 0; }

.create-form__inputs {
  padding-right: 2rem;
  transition: opacity 120ms linear; }

.form[data-busy="true"] .create-form__inputs,
.form[data-success="true"] .create-form__inputs {
  pointer-events: none;
  user-select: none;
  opacity: 0.5; }

.breadcrumb {
  padding: 0.625rem 4.375rem;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2); }
  .breadcrumb ol.flex-row, .breadcrumb li.flex-row {
    align-items: center; }
  .breadcrumb li {
    margin-right: 0.25rem; }
    .breadcrumb li a {
      padding: 0.625rem;
      font-size: 1.125em;
      font-weight: 300;
      border-radius: 0.25rem;
      user-select: none; }
      .breadcrumb li a:hover {
        background: rgba(255, 255, 255, 0.1); }
      .breadcrumb li a:active {
        background: rgba(255, 255, 255, 0.2); }
    .breadcrumb li img {
      pointer-events: none;
      user-select: none;
      margin-left: 0.25rem; }
    .breadcrumb li:last-child {
      margin-right: 0; }
      .breadcrumb li:last-child img {
        display: none; }
